const swiper1 = new Swiper('#mainTeamList', {
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    pagination: {
        el: '#mainTeamListPagination',
        clickable: true,
    },
    breakpoints: {
        980: {
            slidesPerView: 3,
            centeredSlides: true
        },
        680: {
            slidesPerView: 2.2,
            centeredSlides: true,
        },
        0: {
            slidesPerView: 1.2,
            centeredSlides: true,
        }
    }
});

const swiper2 = new Swiper('#mainBoxes', {
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    pagination: {
        el: '#mainBoxesPagination',
        clickable: true,
    },
    breakpoints: {
        980: {
            slidesPerView: 3,
            centeredSlides: true
        },
        680: {
            slidesPerView: 2.2,
            centeredSlides: true,
        },
        0: {
            slidesPerView: 1.2,
            centeredSlides: true,
        }
    }
});

let mySwiper;

const media = window.matchMedia("(max-media: 750px)");
media.addEventListener("change", media => {
    checkBreakpoints(media);
})
checkBreakpoints(media);

function checkBreakpoints(media) {
    if (media.matches) {
        if ( mySwiper !== undefined ) {
            mySwiper.destroy( true, true );
        }
        return;
    } else {
        return enableSwiper();
    }
}

function enableSwiper() {
    const swiper3 = new Swiper('#mainServices', {
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        pagination: {
            el: '#mainServicesPagination',
            clickable: true,
        }
    });
}