import { getScrollWidth } from "./_utility";

$(document).on("click", '.popupOpen', function(e) {
    e.preventDefault();
    const href = $(this).attr('href');
    const $popup = $(href);

    $(document.body).addClass('popup-is-visible');
    $(document.body).css('padding-right', getScrollWidth);
    $popup.fadeIn();
})

document.addEventListener("keydown", e => {
    if (e.key && e.key.toUpperCase() === "ESCAPE") {
        $('.popup:visible .popup-close').trigger("click");
    }
})

$(document).on("click", '.popup:visible .popup-close', function() {
    $(this).parents('.popup').fadeOut(function() {
        $(document.body).removeClass('popup-is-visible');
        $(document.body).css('padding-right', '');
    });
});

$(document).on('click', '.popup-container', function(e) {
    e.stopPropagation();
});
