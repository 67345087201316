const header = document.querySelector(".p-header");

const toggleHeader = e => {
    if (window.scrollY > 50) {
        header.classList.add("is-sticky");
    } else {
        header.classList.remove("is-sticky");
    }
}

const media = window.matchMedia("(min-width: 1200px)");
if (media.matches) {
    window.addEventListener("scroll", toggleHeader)
}

media.addEventListener("change", media => {
    if (media.matches) {
        window.addEventListener("scroll", toggleHeader)
    } else {
        header.classList.remove("is-sticky");
        window.removeEventListener("scroll", toggleHeader)
    }
})