const $contactForms = $('#contactForm, #mContactForm');

$contactForms.each(function() {
    const $form = $(this);

    const $inputs = $form.find('input[type=text], input[type=email], textarea');

    $inputs.each(function() {
        $(this).on("input", e => {
            if ($(this).get(0).checkValidity()) {
                $(this).removeClass('form-control-error');
            } else {
                $(this).addClass('form-control-error');
            }
        })
    });

    $form.attr('novalidate', 'novalidate');
    $form.on('submit', function(e) {
        e.preventDefault();

        const $submit = $form.find(':submit');
        let formValid = true;

        $inputs.each(function() {
            if ($(this).get(0).checkValidity()) {
                $(this).removeClass('form-control-error');
            } else {
                $(this).addClass('form-control-error');
                formValid = false;
            }
        });

        if (!formValid) {
            return false;
        }

        $submit.attr('disabled', true).addClass('is-loading');

        $.ajax({
            url : "/sendMessage.php",
            method : "post",
            dataType : 'json',
            data : {
                name : $form.find('[name=name]').val(),
                email : $form.find('[name=email]').val(),
                message : $form.find('[name=msg]').val(),
                title : $("header title").html()
            }
        }).done(function(ret) {
            if (ret.status === 'error') {
                if (!$form.find('.contact-form-send-error').length) {
                    $submit.after('<div class="contact-form-send-error">Wystąpił błąd przy wysyłaniu wiadomości</div>')
                }
            } else {
                let $template = $("#formContactSendSuccessful");
                let html = $template.html();
                $form.replaceWith($(html));
            }
        }).always(function() {
            $submit.attr('disabled', false).removeClass('loading');
        })

    })
})

