export function getScrollWidth() {
    const divTest = document.createElement("div");
    divTest.style.cssText = `overflow-y: scroll; opacity:0; height: 55px; position: absolute; bottom:0;`
    divTest.innerHTML = `<div style="height: 100px">koty są fajne i psy też</div>`;
    document.body.appendChild(divTest);

    const scrollWidth = divTest.offsetWidth - divTest.clientWidth;
    divTest.remove();

    return scrollWidth;
}